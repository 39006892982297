import { PopoverTrigger } from '@radix-ui/react-popover'
import dayjs from 'dayjs'
import type { Matcher } from 'react-day-picker'
import { Calendar } from './calendar'
import { Popover, PopoverContent } from './popover'
import { TextField } from './textfield'

export function DatePicker({
  value,
  onChange,
  matcher,
}: {
  value?: string | null
  onChange: (date: string | null) => void
  matcher?: Matcher | Matcher[]
}) {
  return (
    <Popover>
      <PopoverTrigger asChild disabled={!value}>
        <TextField value={value ? dayjs(value).format('L') : ''} />
      </PopoverTrigger>
      {value && (
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            disabled={matcher}
            mode="single"
            selected={new Date(value)}
            onSelect={(date) => date && onChange(date.toString())}
            initialFocus
          />
        </PopoverContent>
      )}
    </Popover>
  )
}
