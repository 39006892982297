import { useUpdateForm } from '@/forms/mutations/useUpdateForm'
import { useForm } from '@/forms/queries/useForm'
import { ReloadIcon } from '@radix-ui/react-icons'
import dayjs from 'dayjs'
import { type ReactNode, useState } from 'react'
import type { DateBefore } from 'react-day-picker'
import { Icon } from './Icon'
import { DatePicker } from './ui/DatePicker'
import { Button } from './ui/button'
import { Checkbox } from './ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog'

export function EditFormCampaignPeriod({
  children,
  formId,
}: { children: ReactNode; formId: string }) {
  const form = useForm({ id: formId })
  const updateForm = useUpdateForm({ formId })

  if (!form.data) return null

  const [time, setTime] = useState(form.data.campaignEndsAt)

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <CampaignPeriodContent setTime={setTime} time={time} />
        <DialogFooter>
          <DialogClose asChild>
            <Button
              disabled={
                updateForm.isPending || form.data.campaignEndsAt === time
              }
              type="submit"
              onClick={onUpdate}
            >
              {updateForm.isPending && (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  &nbsp;
                </>
              )}
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )

  function onUpdate() {
    if (!time) {
      updateForm.mutateAsync({ campaignEndsAt: 'undefined' })
    } else {
      updateForm.mutateAsync({
        campaignEndsAt: dayjs(time).toISOString(),
      })
    }
  }
}

export function CampaignPeriodContent({
  time,
  setTime,
}: {
  time: string | null
  setTime: (date: string | null) => void
}) {
  const afterMatcher: DateBefore = {
    before: new Date(dayjs().add(1, 'day').toString()),
  }

  return (
    <div className="pb-[16px]">
      <DialogHeader>
        <div className="flex gap-[16px] items-center">
          <div className="bg-[#D0BCFF] rounded-[8px] p-[20px]">
            <Icon name="pen-to-square" size={16} color="#121212" />
          </div>
          <DialogTitle>Form end date</DialogTitle>
        </div>
        <DialogDescription className="pt-[16px]">
          This is the date the form will close. After this date, responses are
          no longer accepted.
        </DialogDescription>
      </DialogHeader>
      <div className="flex flex-col gap-[16px] pt-[36px]">
        <DatePicker value={time} onChange={setTime} matcher={afterMatcher} />
        <div className="flex gap-[15px] items-center">
          <Checkbox
            id="campaignEndsAt"
            checked={!time}
            onCheckedChange={(checked) => {
              if (checked) {
                setTime(null)
              } else {
                setTime(dayjs().add(1, 'day').toString())
              }
            }}
          />
          <label
            htmlFor="campaignEndsAt"
            className="text-[16px] font-[400] leading-[24px] tracking-[.5px] text-[#E6E0E9] cursor-pointer"
          >
            Infinite period
          </label>
        </div>
      </div>
    </div>
  )
}
