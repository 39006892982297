import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { TextField } from '@/components/ui/textfield';
import { useCreateForm } from '@/forms/mutations/useCreateForm';
import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import { CampaignPeriodContent } from './EditFormCampaignPeriod';
import { Icon } from './Icon';

type Step = 'createNewCampaign' | 'campaignEndDate';

export function CreateFormDialog({
  open,
  onChange,
}: { open: boolean; onChange: (open: boolean) => void }) {
  const [step, setStep] = useState<Step>('createNewCampaign');
  const [campaignEndDate, setCampaignEndDate] = useState<string | null>('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const { address } = useAccount();
  const createForm = useCreateForm();

  const resetForm = (openState: boolean) => {
    if (openState) {
      setStep('createNewCampaign');
      setCampaignEndDate('');
      setTitle('');
      setDescription('');
    }
    onChange(openState);
  };

  return (
    <Dialog open={open} onOpenChange={resetForm}>
      <DialogTrigger asChild className="max-w-[360px] w-full">
        <div className="flex w-full">
          <CreateNewFormButton />
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] border-border">
        {step === 'createNewCampaign' && (
          <>
            <DialogHeader>
              <div className="flex items-center justify-between">
                <DialogTitle>Create form</DialogTitle>
                <DialogClose className="p-[8px] hover:bg-[#2A2B2F] transition-colors">
                  <Icon size={24} name="close" color="#CAC4D0" />
                </DialogClose>
              </div>
              <DialogDescription className="py-[16px]">
                Create a new form and gather valuable data with Nuklai
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col gap-[24px]">
              <div className="flex flex-col gap-[8px] pb-[8px]">
                <Label
                  htmlFor="title"
                  className="text-[#CAC5CD] text-[16px] leading-[24px] tracking-[.25px] font-[400]"
                >
                  Form name
                </Label>
                <TextField
                  id="title"
                  placeholder="Choose a name"
                  value={title}
                  onChange={({ target: { value } }) => setTitle(value)}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <Label
                  htmlFor="description"
                  className="text-[#CAC5CD] text-[16px] leading-[24px] tracking-[.25px] font-[400]"
                >
                  Form description
                </Label>
                <TextField
                  id="description"
                  placeholder="Provide a brief description"
                  value={description}
                  onChange={({ target: { value } }) => setDescription(value)}
                  className="resize-none"
                />
              </div>
            </div>
            <DialogFooter className="pt-[40px]">
              <Button type="submit" onClick={() => setStep('campaignEndDate')}>
                Continue
              </Button>
            </DialogFooter>
          </>
        )}

        {step === 'campaignEndDate' && (
          <>
            <CampaignPeriodContent
              time={campaignEndDate}
              setTime={setCampaignEndDate}
            />
            <DialogFooter className="pt-[40px]">
              <Button
                variant="outline"
                type="submit"
                onClick={() => setStep('createNewCampaign')}
                className="mr-[8px]"
              >
                Previous
              </Button>
              <Button
                disabled={!validateInput() || createForm.isPending}
                type="submit"
                onClick={async () => {
                  if (!address) return null;

                  const form = await createForm.mutateAsync({
                    title,
                    description,
                    campaignEndsAt: campaignEndDate
                      ? dayjs(campaignEndDate).toISOString()
                      : 'undefined',
                  });

                  if (form.id) {
                    navigate({
                      to: '/forms/$id/$mode',
                      params: { id: form.id, mode: 'edit' },
                    });
                  }
                }}
              >
                {createForm.isPending && (
                  <>
                    <Icon name="spinner" size={24} color="#1D192B" />
                    &nbsp;
                  </>
                )}
                Save
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );

  function validateInput() {
    return !!title && !!description;
  }
}

function CreateNewFormButton() {
  return (
    <Button className="flex flex-col items-center justify-center gap-[10px] w-full border border-[#3A393C] border-dashed rounded-[4px] h-[128px] bg-[#121212] hover:border-[#605D64] [&>p]:hover:text-[#CAC5CD] hover:bg-[#332D41]/[.12] active:bg-[#212226] [&>p]:active:text-[#CAC5CD] active:border-[#756B7D] [&_.mask]:hover:!bg-[#CAC5CD] [&_.mask]:active:!bg-[#CAC5CD] disabled:border-none disabled:bg-[#212226] [&>p]:disabled:text-[#48464C] [&_.mask]:disabled:!bg-[#69656E] [&_.mask]:!transition-colors">
      <Icon name="new-file" width={18} height={24} color="#938F96" />
      <p className="font-[400] text-[16px] text-[#938F96] leading-[24p] tracking-[.25px] transition-colors">
        Create a new form
      </p>
    </Button>
  );
}
