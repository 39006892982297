import type { Question } from '@/forms/domains/form';
import { AxiosError, type AxiosResponse } from 'axios';
import { type ClassValue, clsx } from 'clsx';
import DOMPurify from 'dompurify';
import type { KeyboardEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import * as uuid from 'uuid';
import { scrollToBottom, scrollToElement } from './scroll';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function shortenAddress(address: string) {
  return `${address.substring(0, 6)} ... ${address.substring(
    address.length - 6,
  )}`;
}

export function generateId() {
  return uuid.v4();
}

export function openTab(url: string) {
  window.open(url, '_blank')?.focus();
}

export function copyToClipBoard(value: string): Promise<void> {
  return navigator.clipboard.writeText(value);
}

export function onKeyboardAction<T>(cb: (e?: KeyboardEvent<T>) => void) {
  return (e: KeyboardEvent<T>) => {
    if (
      e.code === 'Enter' ||
      e.key === 'Enter' ||
      e.keyCode === 13 ||
      e.code === 'Space' ||
      e.key === ' '
    ) {
      cb(e);
    }
  };
}

export function sanitizeHTML(value: string) {
  return {
    __html: DOMPurify.sanitize(value, {
      USE_PROFILES: { html: true },
    }),
  };
}

export function isProduction() {
  return import.meta.env.VITE_APP_ENV === 'prod';
}

interface ErrorWithResponse {
  code: number;
  data: string;
  message: string;
  status: string;
}

export function errorMessageOf(error: unknown) {
  if (
    error instanceof AxiosError &&
    error.response &&
    isErrorWithResponse(error.response)
  ) {
    return error.response?.data?.data;
  }

  return undefined;
}

function isErrorWithResponse(
  response: AxiosResponse<unknown> | AxiosResponse<ErrorWithResponse>,
): response is AxiosResponse<ErrorWithResponse> {
  return (
    !!response.data &&
    typeof response.data === 'object' &&
    'code' in response.data
  );
}

export function scrollToQuestion(questionId: string) {
  const target = document.getElementById(questionId);
  const container = document.getElementById('form-editor');

  if (!target || !container) return;

  scrollToElement(container, target, 600);
}

export function scrollToEditorBottom() {
  const container = document.getElementById('form-editor');

  if (!container) return;

  scrollToBottom(container, 600);
}

interface MetaMaskError {
  code: number;
  message: string;
}

export function isMetamaskError(
  error: unknown | MetaMaskError,
): error is MetaMaskError {
  return !!((error as MetaMaskError).code && (error as MetaMaskError).message);
}

export function calculatePageIndicator(
  itemId: string,
  questionList: Question[],
) {
  return (
    questionList
      .filter((question) => question.type === 'new_page')
      .findIndex((question) => question.id === itemId) + 2
  );
}

export function findQuestionPage(questionId: string, questions: Question[]) {
  const position = questions.findIndex(
    (question) => question.id === questionId,
  );

  const questionsSlice = questions.slice(0, position + 1);

  return questionsSlice.reduce((acc, question) => {
    if (question.type === 'new_page') {
      return acc + 1;
    }

    return acc;
  }, 1);
}

export function calculateQuestionIndicator(
  itemId: string,
  questionList: Question[],
) {
  return (
    questionList
      .filter((question) => question.type !== 'new_page')
      .findIndex((question) => question.id === itemId) + 1
  );
}

export function getDataQuestions(questions: Question[]) {
  return questions.filter((question) => isDataQuestion(question));
}

export function isDataQuestion(question: Question) {
  return question.type !== 'new_page';
}
