import type { Form } from '@/forms/domains/form';
import { formatDate } from '@/lib/dateUtils';

type FormHeader = Pick<Form, 'id' | 'title' | 'description' | 'campaignEndsAt'>;

export function ReadonlyFormHeader({ form }: { form: FormHeader }) {
  return (
    <div>
      <div className="h-[8px] bg-[#4A4458] rounded-t-[8px]">&nbsp;</div>
      <div className="bg-[#212226] px-[36px] pb-[48px] pt-[40px] flex flex-col gap-[24px] text-left rounded-b-[2px]">
        <div className="text-[32px] leading-[40px] text-[#FEFEFE] px-0 focus-visible:!pl-0 border-none">
          {form.title}
        </div>
        {form.campaignEndsAt && (
          <div className="text-[#AEA9B1] font-[400] tracking-[.25px] text-[16px] leading-[25px] flex items-center gap-[8px]">
            <span>End date</span>
            <span className="text-[#FFFFFF]">
              {formatDate(form.campaignEndsAt, 'll')}
            </span>
          </div>
        )}
        <div className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD] px-0 focus-visible:!pl-0 focus-within:pt-[12px] min-h-fit resize-none border-none">
          {form.description}
        </div>
      </div>
    </div>
  );
}
