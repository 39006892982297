import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export function formatDate(date: string, format: string) {
  return dayjs(date).format(format);
}

export function isBeforeNow(date: string) {
  return dayjs(date).isBefore(dayjs());
}

export function getCurrentDate(): string {
  return dayjs().utc().format();
}
