import type { NumericalItem, PositionedField } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import {
  QuestionClientSupportingText,
  QuestionEditableSupportingText,
} from '../QuestionEditableSupportingText';
import { TextField } from '../ui/textfield';
import { EditableFieldWrapper } from './EditableFieldWrapper';
import { ReadableFieldWrapper } from './ReadableFieldWrapper';

export function NumericalField({
  field,
}: {
  field: PositionedField<NumericalItem>;
}) {
  const updateQuestion = useUpdateQuestion({
    formId: field.formId,
  });

  return (
    <EditableFieldWrapper field={field}>
      <div className="flex flex-col gap-[4px] -ml-[16px]">
        <TextField
          placeholder="Add a numerical value, e.g., 23"
          type="number"
          className="!w-1/2"
        />
        <div className="pl-[16px]">
          <QuestionEditableSupportingText
            value={field.options.supportingText}
            onChange={(value) =>
              updateQuestion.mutateAsync({
                questionId: field.id,
                options: {
                  ...field.options,
                  supportingText: value,
                },
              })
            }
          />
        </div>
      </div>
    </EditableFieldWrapper>
  );
}

export function ClientNumericalField({
  field,
  response,
  onChange,
}: {
  field: PositionedField<NumericalItem>;
  response?: string;
  onChange?: (value: number) => void;
}) {
  return (
    <ReadableFieldWrapper field={field}>
      <div className="flex flex-col gap-[4px] items-start -ml-[16px]">
        <TextField
          placeholder="Add a numerical value, e.g., 23"
          type="number"
          value={response}
          onChange={({ target: { value } }) =>
            onChange?.(Number.parseFloat(value))
          }
          className="!w-1/2"
        />
        {field.options.supportingText && (
          <div className="pl-[16px]">
            <QuestionClientSupportingText
              value={field.options.supportingText}
            />
          </div>
        )}
      </div>
    </ReadableFieldWrapper>
  );
}
