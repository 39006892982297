import type { PositionedField, Question } from '@/forms/domains/form'
import { isDataQuestion } from '@/lib/utils'
import type { ReactNode } from 'react'
import { getContentDefinition } from '../AddFieldDialog/questionDefinition'
import { Icon } from '../Icon'
import { QuestionClientDescription } from '../QuestionEditableDescription'
import { QuestionClientTitle } from '../QuestionEditableTitle'

export function ReadableFieldWrapper({
  children,
  field,
}: {
  children: ReactNode
  field: PositionedField<Question>
}) {
  const questionDefinition = getContentDefinition(field.type)

  if (!questionDefinition) return null

  return (
    <div
      className="pr-[36px] py-[20px] flex flex-col gap-[16px] rounded-[4px] border-transparent pl-[37px] hover:bg-[#191919] hover:pl-[36px] hover:border-l transition-all"
      style={{
        borderColor: questionDefinition.backgroundColor,
      }}
    >
      <div className="flex w-full gap-[8px]">
        <div className="flex items-center gap-[16px]">
          <div
            className="text-[16px] font-[600] leading-[28px] min-w-[18px]"
            style={{ color: questionDefinition.backgroundColor }}
          >
            {field.indicator}
          </div>
          <span
            className="w-[24px] h-[24px] flex items-center justify-center rounded-[2px]"
            style={{ background: questionDefinition.backgroundColor }}
          >
            <Icon
              name={questionDefinition.icon}
              width={questionDefinition.width}
              height={questionDefinition.height}
              color="#381E72"
            />
          </span>
        </div>
        <div className="flex justify-between w-full">
          <QuestionClientTitle value={field.title} />
          {isDataQuestion(field) && field.options.required && (
            <div className="text-destructive whitespace-nowrap self-center">
              * required
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-[16px] pl-[48px]">
        {field.description && (
          <QuestionClientDescription value={field.description} />
        )}
        <div className="pl-[24px]">{children}</div>
      </div>
    </div>
  )
}
