import { Button } from '@/components/ui/button'
import type { Form } from '@/forms/domains/form'
import { useFormStats } from '@/forms/queries/useFormStats'
import { useQuestions } from '@/forms/queries/useQuestions'
import { formatDate } from '@/lib/dateUtils'
import { useNavigate } from '@tanstack/react-router'
import { FormItemContextMenu } from './FormItemContextMenu'

export function FormListItem({ form }: { form: Form }) {
  const questions = useQuestions({ formId: form.id })
  const formStats = useFormStats({ formId: form.id })
  const navigate = useNavigate()
  const isPublished = form.status === 'published'
  const questionsCounter = questions.data?.length || 0

  return (
    <tr className="group transition-all">
      <td className="py-[10px] pl-[16px] flex gap-[16px] bg-[#212226] rounded-l-[8px]">
        <div className="flex flex-col">
          <div className="flex gap-[8px] items-center">
            <div className="text-[16px] font-[700] leading-[24px] text-[#CAC5CD] text-left">
              {form.title}
            </div>
            {isPublished && (
              <div className="text-[#8DEFB4] text-[14px] font-[400] leading-[20px] tracking-[.25px]">
                Published
              </div>
            )}
          </div>
          <div className="text-[14px] font-[400] leading-[20px] tracking-[.25px] flex gap-[16px]">
            <div className="text-[#605D64]">
              Created: {formatDate(form.createdAt, 'll')}
            </div>
            {form.campaignEndsAt && (
              <div className="text-[#605D64]">
                End date: {formatDate(form.createdAt, 'll')}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="py-[10px] text-left bg-[#212226]">
        {!isPublished && (
          <Button
            variant="text"
            className="px-[24px] rounded-[4px] text-[#CAC5CD] leading-[16px] bg-[#2A2B2F] hover:bg-[#2A2B2F] active:bg-[#2A2B2F] invisible group-hover:visible"
            onClick={() =>
              navigate({
                to: '/forms/$id/$mode',
                params: { id: form.id, mode: 'edit' },
              })
            }
          >
            Edit
          </Button>
        )}
      </td>
      <td className="py-[10px] text-left text-[#CAC5CD] text-[14px] font-[300] leading-[20px] tracking-[.25px] bg-[#212226]">
        {questionsCounter}
      </td>
      <td className="py-[10px] text-left text-[#CAC5CD] text-[14px] font-[300] leading-[20px] tracking-[.25px] bg-[#212226]">
        {formStats.data?.total_responses || 0}
      </td>
      <td className="py-[10px] text-left text-[#CAC5CD] text-[14px] font-[300] leading-[20px] tracking-[.25px] bg-[#212226]">
        {formatDate(form.updatedAt, 'll')}
      </td>
      <td className="py-[10px] pr-[16px] text-left bg-[#212226] rounded-r-[8px]">
        <FormItemContextMenu form={form} stats={formStats.data} />
      </td>
    </tr>
  )
}
