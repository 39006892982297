import type { PositionedField, Question } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import { cn, isDataQuestion, scrollToQuestion } from '@/lib/utils';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import type { ReactNode } from 'react';
import { getContentDefinition } from '../AddFieldDialog/questionDefinition';
import { Icon } from '../Icon';
import { FormEditableDescription } from '../QuestionEditableDescription';
import { QuestionEditableTitle } from '../QuestionEditableTitle';

export function EditableFieldWrapper({
  children,
  field,
}: {
  children: ReactNode;
  field: PositionedField<Question>;
}) {
  const navigate = useNavigate();
  const { mode } = useParams({ from: '/forms/$id/$mode' });
  const selectedQuestionId = useSearch({
    from: '/forms/$id/$mode',
    select: (search) => search.q,
  });
  const questionDefinition = getContentDefinition(field.type);
  const updateQuestion = useUpdateQuestion({
    formId: field.formId,
  });

  if (!questionDefinition) return null;

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      className={cn(
        'px-[36px] py-[20px] flex flex-col gap-[16px] rounded-[4px] border-l border-transparent transition-all',
        field.id === selectedQuestionId && 'bg-[#191919]',
      )}
      style={{
        borderColor:
          field.id === selectedQuestionId
            ? questionDefinition.backgroundColor
            : 'transparent',
      }}
      onClick={() => {
        scrollToQuestion(field.id);
        navigate({
          to: '/forms/$id/$mode',
          params: { id: field.formId, mode },
          search: { q: field.id },
        });
      }}
    >
      <div className="flex w-full gap-[8px] items-start">
        <div className="flex items-center gap-[16px] pt-[8px]">
          <div
            className="text-[16px] font-[600] leading-[28px] min-w-[18px]"
            style={{ color: questionDefinition.backgroundColor }}
          >
            {field.indicator}
          </div>
          <span
            className="w-[24px] h-[24px] flex items-center justify-center rounded-[2px]"
            style={{ background: questionDefinition.backgroundColor }}
          >
            <Icon
              name={questionDefinition.icon}
              width={questionDefinition.width}
              height={questionDefinition.height}
              color="#381E72"
            />
          </span>
        </div>
        <QuestionEditableTitle
          value={field.title ?? 'Your question here.'}
          onChange={(value) =>
            updateQuestion.mutateAsync({
              questionId: field.id,
              title: value,
            })
          }
        />
        {isDataQuestion(field) && field.options.required && (
          <div className="text-destructive whitespace-nowrap self-center">
            * required
          </div>
        )}
      </div>
      <div className="flex flex-col gap-[16px] pl-[56px]">
        <FormEditableDescription
          value={field.description ?? ''}
          onChange={(value) =>
            updateQuestion.mutateAsync({
              questionId: field.id,
              description: value,
            })
          }
        />
        <div className="pl-[16px]">{children}</div>
      </div>
    </div>
  );
}
