import debounce from 'lodash.debounce'
import { useEffect, useMemo, useState } from 'react'
import { TextField } from './ui/textfield'

export function QuestionEditableTitle({
  value,
  onChange,
}: {
  value: string
  onChange: (value: string) => void
}) {
  const [editValue, setEditValue] = useState(value)

  useEffect(() => setEditValue(value), [value])

  const debouncedUpdate = useMemo(
    () => debounce((val) => onChange(val), 750),
    [onChange],
  )
  const reasonForDisabled = getReasonsForError()

  return (
    <TextField
      className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD] border-none focus-visible:!pl-[8px] focus-within:!text-[#E6E0E9] caret-[#D0BCFF] px-[8px]"
      value={editValue}
      placeholder="Add title"
      onChange={({ target: { value } }) => {
        setEditValue(value)
        debouncedUpdate(value)
      }}
      error={reasonForDisabled}
      onBlur={() => {
        if (reasonForDisabled) {
          setEditValue(value)
        }
      }}
    />
  )

  function getReasonsForError() {
    if (editValue.length > 200)
      return 'Title must be shorter than 200 characters'
  }
}

export function QuestionClientTitle({ value }: { value: string }) {
  return (
    <div className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-left text-[#CAC5CD] px-[8px] py-[4px]">
      {value}
    </div>
  )
}
