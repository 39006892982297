import type { Form, Question } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import { useForm } from '@/forms/queries/useForm';
import { useQuestions } from '@/forms/queries/useQuestions';
import {
  calculatePageIndicator,
  calculateQuestionIndicator,
  cn,
  findQuestionPage,
} from '@/lib/utils';
import { scrollToQuestion } from '@/lib/utils';
import {
  DndContext,
  type DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { AddFieldPanel } from '../AddFieldDialog/AddFieldDialog';
import {
  type ContentDefinition,
  getContentDefinition,
} from '../AddFieldDialog/questionDefinition';
import { Icon } from '../Icon';
import { QuestionIndicator } from '../QuestionIndicator';
import { QuestionItemContextMenu } from './QuestionItemContextMenu';

export function TableOfContent({ formId }: { formId: string }) {
  const questions = useQuestions({ formId });
  const form = useForm({ id: formId });
  const updateQuestion = useUpdateQuestion({
    formId,
  });
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 1 } }),
    useSensor(PointerSensor, { activationConstraint: { distance: 1 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 1 } }),
  );
  const questionList = questions.data ?? [];

  if (!form.data) return null;

  return (
    <div className="bg-[#212226] max-w-[240px] w-full">
      <div className="flex justify-between pl-[24px] pr-[10px] py-[16px] items-center">
        <div className="text-[14px] leading-[24px] tracking-[.5px] font-[400] text-[#D4D4D4]">
          Content
        </div>
        <AddFieldPanel form={form.data} />
      </div>
      <div className="flex flex-col h-[calc(100%_-_192px)] overflow-y-auto">
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={questionList}>
            {questionList.map((question) => (
              <QuestionItem
                key={question.id}
                question={question}
                form={form.data}
                questionList={questions.data ?? []}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = questionList.findIndex(
        (question) => question.id === active.id,
      );
      const newIndex = questionList.findIndex(
        (question) => question.id === over.id,
      );
      const updatedList = arrayMove(questionList, oldIndex, newIndex);

      updatedList.forEach((question, index) => {
        updateQuestion.mutate({
          // TODO optimize, create move endpoint
          questionId: question.id,
          order: index + 1,
        });
      });
    }
  }
}

function QuestionItem({
  question,
  form,
  questionList,
}: { question: Question; form: Form; questionList: Question[] }) {
  const questionDefinition = getContentDefinition(question.type);
  const navigate = useNavigate();
  const selectedQuestionId = useSearch({
    from: '/forms/$id/$mode',
    select: (search) => search.q,
  });
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: question.id });
  const { mode } = useParams({ from: '/forms/$id/$mode' });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (!questionDefinition) return null;

  if (questionDefinition.id === 'new_page') {
    return (
      <div
        ref={setNodeRef}
        className={cn(isDragging && 'z-[1000]')}
        style={style}
        {...attributes}
        {...listeners}
        onClick={onItemClick}
      >
        <PageItem
          question={question}
          form={form}
          indicator={calculatePageIndicator(question.id, questionList)}
        />
      </div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      className={cn(isDragging && 'z-[1000]')}
      style={style}
      onClick={onItemClick}
      {...attributes}
      {...listeners}
    >
      <EditableQuestionItem
        selected={question.id === selectedQuestionId}
        indicator={calculateQuestionIndicator(question.id, questionList)}
        contentDefinition={questionDefinition}
        form={form}
        question={question}
      />
    </div>
  );

  function onItemClick() {
    const questionPage = findQuestionPage(question.id ?? '', questionList);

    navigate({
      to: '/forms/$id/$mode',
      params: { id: question.formId, mode },
      search: { q: question.id, page: questionPage },
    });

    setTimeout(() => scrollToQuestion(question.id), 300);
  }
}

function PageItem({
  indicator,
  question,
  form,
}: { indicator: number; question: Question; form: Form }) {
  return (
    <div className="py-[12px] cursor-pointer hover:bg-[#26272C] group flex justify-between items-center px-[14px] text-[#CAC5CD] text-[14px] leading-[20px] tracking-[0.25px] font-[300] relative">
      <Icon
        name="dots-vertical"
        width={4}
        height={16}
        color="#4F5358"
        className="opacity-0 group-hover:!opacity-100 transition-opacity"
      />
      <div className="select-none px-[14px] bg-[#212226] group-hover:bg-[#26272C] z-10">
        page {indicator}
      </div>
      <QuestionItemContextMenu question={question} form={form}>
        {({ open }) => (
          <Icon
            name="dots"
            size={22}
            className={cn(
              'opacity-0 group-hover:!opacity-100 transition-opacity',
              open && 'opacity-100',
            )}
          />
        )}
      </QuestionItemContextMenu>
      <div className="h-[1px] top-1/2 left-0 absolute w-full pl-[30px] pr-[45px]">
        <div className="h-[1px] bg-[#383838] w-full" />
      </div>
    </div>
  );
}

function EditableQuestionItem({
  selected,
  indicator,
  contentDefinition,
  form,
  question,
}: {
  selected: boolean;
  indicator: number;
  contentDefinition: ContentDefinition;
  form: Form;
  question: Question;
}) {
  return (
    <div
      className={cn(
        'py-[12px] px-[14px] flex justify-between group cursor-pointer hover:bg-[#26272C]',
        selected && 'bg-[#2A2B2F]',
      )}
    >
      <div className="flex items-center gap-[7px]">
        <Icon
          name="dots-vertical"
          width={4}
          height={16}
          color="#4F5358"
          className={cn(
            'opacity-0 group-hover:!opacity-100 transition-opacity',
            selected && 'opacity-100',
          )}
        />
        <QuestionIndicator
          definition={contentDefinition}
          order={indicator}
          className="select-none"
        />
      </div>
      <QuestionItemContextMenu question={question} form={form}>
        {({ open }) => (
          <Icon
            name="dots"
            size={22}
            className={cn(
              'opacity-0 group-hover:!opacity-100 transition-opacity',
              (selected || open) && 'opacity-100',
            )}
          />
        )}
      </QuestionItemContextMenu>
    </div>
  );
}
