import type { Form, PositionedField, Question } from '@/forms/domains/form';
import { cn } from '@/lib/utils';
import type { FormDraftResponse } from '@/response/types';
import { ReadonlyFormHeader } from './ReadonlyFormHeader';
import { ClientMultipleChoiceField } from './fields/MultipleChoiceField';
import { ClientNumericalField } from './fields/NumericalField';
import { ClientParagraphField } from './fields/ParagraphField';
import { ClientShortTextField } from './fields/ShortTextField';
import { ClientSingleChoiceField } from './fields/SingleChoiceField';

interface FormPreviewProps {
  form: Form;
  fields: PositionedField<Question>[];
  responses?: FormDraftResponse;
  onChangeResponse?: (questionId: string, value: string | number) => void;
  className?: string;
}

export function FormPreview({
  form,
  fields,
  responses,
  onChangeResponse,
  className,
}: FormPreviewProps) {
  const headerItem = getHeaderItem(fields);
  const formHeader = headerItem
    ? {
        id: form.id,
        title: headerItem.title,
        description: headerItem.description,
        campaignEndsAt: form.campaignEndsAt,
      }
    : form;

  return (
    <div className={cn('p-[24px] w-full flex flex-col gap-[36px]', className)}>
      <ReadonlyFormHeader form={formHeader} />
      {fields.map((positionedQuestion) => (
        <div key={positionedQuestion.id} id={positionedQuestion.id}>
          <QuestionContent
            field={positionedQuestion}
            response={responses?.[positionedQuestion.id] ?? ''}
            onChange={(value) =>
              onChangeResponse?.(positionedQuestion.id, value)
            }
          />
        </div>
      ))}
    </div>
  );
}

function QuestionContent<Q extends Question>({
  field,
  response,
  onChange,
}: {
  field: PositionedField<Q>;
  response?: string;
  onChange?: (value: string | number) => void;
}) {
  if (field.type === 'short_text') {
    return (
      <ClientShortTextField
        field={field}
        response={response}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'paragraph') {
    return (
      <ClientParagraphField
        field={field}
        response={response}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'numerical') {
    return (
      <ClientNumericalField
        field={field}
        response={response}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'multi_choice_list') {
    return (
      <ClientMultipleChoiceField
        field={field}
        response={response}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'single_choice_list') {
    return (
      <ClientSingleChoiceField
        field={field}
        response={response}
        onChange={onChange}
      />
    );
  }

  return null;
}

function getHeaderItem(
  fields: PositionedField<Question>[],
): PositionedField<Question> | null {
  const field = fields.at(0);

  if (!field || field.type !== 'new_page') return null;
  if (!field.title && !field.description) return null;

  return fields[0];
}
