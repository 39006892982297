import type { ParagraphItem, PositionedField } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import {
  QuestionClientSupportingText,
  QuestionEditableSupportingText,
} from '../QuestionEditableSupportingText';
import { Textarea } from '../ui/textarea';
import { EditableFieldWrapper } from './EditableFieldWrapper';
import { ReadableFieldWrapper } from './ReadableFieldWrapper';

export function ParagraphField({
  field,
}: { field: PositionedField<ParagraphItem> }) {
  const updateQuestion = useUpdateQuestion({
    formId: field.formId,
  });

  return (
    <EditableFieldWrapper field={field}>
      <div className="flex flex-col gap-[4px] -ml-[16px]">
        <Textarea resizable placeholder="Your text here..." />
        <div className="pl-[16px]">
          <QuestionEditableSupportingText
            value={field.options.supportingText}
            onChange={(value) =>
              updateQuestion.mutateAsync({
                questionId: field.id,
                options: {
                  ...field.options,
                  supportingText: value,
                },
              })
            }
          />
        </div>
      </div>
    </EditableFieldWrapper>
  );
}

export function ClientParagraphField({
  field,
  response,
  onChange,
}: {
  field: PositionedField<ParagraphItem>;
  response?: string;
  onChange?: (value: string) => void;
}) {
  return (
    <ReadableFieldWrapper field={field}>
      <div className="flex flex-col gap-[4px] items-start -ml-[16px]">
        <Textarea
          resizable
          placeholder="Your text here..."
          value={response}
          onChange={({ target: { value } }) => onChange?.(value)}
        />
        {field.options.supportingText && (
          <div className="pl-[16px]">
            <QuestionClientSupportingText
              value={field.options.supportingText}
            />
          </div>
        )}
      </div>
    </ReadableFieldWrapper>
  );
}
