import type { Form } from '@/forms/domains/form';
import { FormListItem } from './FormListItem';

interface FormTableProps {
  formsData?: Form[];
}

export function FormTable({ formsData }: FormTableProps) {
  if (!formsData) return null;

  return (
    <table className="border-spacing-y-[8px] border-separate w-full">
      <thead className="sticky top-0 bg-[#121212] z-50">
        <tr>
          <td className="text-left pl-[16px] py-[16px] text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD]">
            My forms
          </td>
          <td>&nbsp;</td>
          <td className="text-left py-[16px] text-[14px] font-[300] leading-[20px] tracking-[.25px] text-[#CAC5CD]">
            Questions
          </td>
          <td className="text-left py-[16px] text-[14px] font-[300] leading-[20px] tracking-[.25px] text-[#CAC5CD]">
            Responses
          </td>
          <td className="text-left py-[16px] text-[14px] font-[300] leading-[20px] tracking-[.25px] text-[#CAC5CD]">
            Updated
          </td>
          <td>&nbsp;</td>
        </tr>
      </thead>

      <tbody>
        {formsData.map((form) => (
          <FormListItem key={form.id} form={form} />
        ))}
      </tbody>
    </table>
  );
}
