import axios from 'axios';
import type { AxiosError } from 'axios';

const REQUEST_TIMEOUT = 15 * 60_000;

export interface QueryParams {
  wallet?: string;
  chainId?: number;
}

export interface GetRequestOptions {
  preventDefaultErrors?: boolean;
}

export interface APIResponse<T> {
  data: T;
  message: string;
  status: string;
  code: number;
}

export function isAPIResponse<T>(
  response: unknown | APIResponse<T>,
): response is APIResponse<T> {
  const maybeResponse = response as APIResponse<T>;
  return (
    !!maybeResponse?.code && !!maybeResponse?.status && !!maybeResponse?.message
  );
}

export function isRequestError<T>(
  error: unknown | AxiosError,
): error is AxiosError<T> {
  return axios.isAxiosError<T>(error);
}

export function getFormsRequest(
  queryParams?: QueryParams,
  headerParams?: Record<string, string>,
  options?: GetRequestOptions,
) {
  const instance = axios.create({
    baseURL: import.meta.env.VITE_APP_FORMS_API,
    timeout: REQUEST_TIMEOUT,
    headers: { ...headerParams },
    params: queryParams,
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token') ?? '';

    config.headers.Authorization = `Bearer ${normalizeToken(token)}`;
    return config;
  });

  if (options?.preventDefaultErrors) return instance;

  return instance;
}

function normalizeToken(token: string) {
  if (token.startsWith('"') && token.endsWith('"')) {
    return token.slice(1, -1);
  }

  return token;
}
