import { useLandingRoute, usePublicRoute } from '@/lib/hooks/usePublicRoute';
import { shortenAddress } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';
import { Icon } from '../Icon';
import { WalletButton, WalletItem } from './WalletButton';

export function Navbar() {
  const navigate = useNavigate();
  const isPublicRoute = usePublicRoute();
  const isLandingPageRoute = useLandingRoute();
  const isPublic = isPublicRoute || isLandingPageRoute;

  return (
    <div className="h-[56px] flex items-center w-full justify-center bg-[#121212]">
      <div className="flex w-full max-w-[1440px] items-center justify-between px-[24px]">
        <Icon
          name="logo"
          width={80}
          height={28.65}
          onClick={() => navigate({ to: isPublic ? '/' : '/forms' })}
          className="cursor-pointer"
        />
        {!isPublic && (
          <WalletButton>
            {({ address, openAccountModal }) => (
              <WalletItem
                name={shortenAddress(address)}
                icon="wallet"
                variant="default"
                onClick={openAccountModal}
              />
            )}
          </WalletButton>
        )}
      </div>
    </div>
  );
}
