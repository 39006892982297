import { EditorTabs } from '@/components/EditorTabs'
import { FormBuilder } from '@/components/FormBuilder'
import { FormEditorPage } from '@/components/FormEditorPage'
import { FormPreview } from '@/components/FormPreview'
import { Schema } from '@/components/Schema'
import { FormSettings } from '@/components/SettingsPanel/FormSettings'
import { QuestionSettings } from '@/components/SettingsPanel/QuestionSettings'
import { TableOfContent } from '@/components/TableOfContent/TableOfContent'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useForm } from '@/forms/queries/useForm'
import { useQuestions } from '@/forms/queries/useQuestions'
import { calculateQuestionPosition, groupByPage } from '@/lib/questions'
import { cn } from '@/lib/utils'
import type { FormDraftResponse } from '@/response/types'
import { useNavigate, useParams, useSearch } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

const settingsTabs = [
  { id: 'question', label: 'Question' },
  { id: 'settings', label: 'Settings' },
]

export function FormView() {
  const { id: formId, mode } = useParams({ from: '/forms/$id/$mode' })
  const form = useForm({ id: formId })
  const questions = useQuestions({ formId })
  const questionId = useSearch({
    from: '/forms/$id/$mode',
    select: (search) => search.q,
  })
  const page =
    useSearch({
      from: '/forms/$id/$mode',
      select: (search) => search.page,
    }) ?? 1
  const positionedFields = calculateQuestionPosition(questions.data ?? [])
  const groupedFields = groupByPage(positionedFields)
  const currentQuestions = groupedFields[page] ?? []
  const [draftPreviewResponse, setDraftPreviewResponse] =
    useState<FormDraftResponse>({})

  const [selectedTab, setSelectedTab] = useState('settings')

  useEffect(() => {
    setSelectedTab(questionId ? 'question' : 'settings')
  }, [questionId])

  if (!form.data) return null

  const isPublished = form.data.status === 'published'

  if (isPublished) return null

  const animatePanelVariant =
    mode === 'edit' || mode === 'schema' ? 'visible' : 'hidden'
  const totalPages = Object.keys(groupedFields).length

  return (
    <FormEditorPage>
      <div className="flex h-full w-full">
        <AnimatePresence>
          {mode === 'edit' && (
            <div key="edit" className="flex h-full flex-[1]">
              <TableOfContent formId={formId} />
              <ScrollArea className="w-full h-full" id="form-editor">
                <FormBuilder form={form.data} />
              </ScrollArea>
            </div>
          )}
          {mode === 'schema' && (
            <div key="schema" className="flex h-full flex-[1]">
              <TableOfContent formId={formId} />
              <ScrollArea
                className="w-full h-[calc(100%_-_148px)]"
                id="form-editor"
              >
                <Schema formId={formId} />
              </ScrollArea>
            </div>
          )}
          {mode === 'preview' && (
            <div key="preview" className="flex h-full w-full">
              <TableOfContent formId={formId} />
              <div className="w-full pb-[148px]">
                <ScrollArea className="w-full h-full" id="form-editor">
                  <FormPreview
                    form={form.data}
                    fields={currentQuestions}
                    responses={draftPreviewResponse}
                    onChangeResponse={(questionId, value) =>
                      setDraftPreviewResponse({
                        ...draftPreviewResponse,
                        [questionId]: value.toString(),
                      })
                    }
                    className={cn(totalPages ? 'pb-[20px]' : 'pb-[148px]')}
                  />
                  {totalPages > 1 && (
                    <QuestionPagination
                      page={page}
                      formId={formId}
                      totalPages={totalPages}
                    />
                  )}
                </ScrollArea>
              </div>
            </div>
          )}
          <motion.div
            initial="hidden"
            animate={animatePanelVariant}
            exit="hidden"
            variants={{
              hidden: { width: 0, transition: { delay: 0.25 } },
              visible: { width: 240 },
            }}
            className="bg-[#212226] pt-[6px]"
          >
            <motion.div
              initial="hidden"
              animate={animatePanelVariant}
              exit="hidden"
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { delay: 0.4 } },
              }}
            >
              <div className="flex justify-center relative">
                <EditorTabs
                  tabs={settingsTabs}
                  selectedTab={selectedTab}
                  layoutId="settings-tabs"
                  onSelect={setSelectedTab}
                />
                <div className="absolute bottom-[3px] h-[1px] w-full bg-[#2A2B2F]" />
              </div>
              {selectedTab === 'question' && questionId && (
                <QuestionSettings formId={formId} questionId={questionId} />
              )}
              {selectedTab === 'settings' && <FormSettings formId={formId} />}
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </div>
    </FormEditorPage>
  )
}

function QuestionPagination({
  page,
  formId,
  totalPages,
}: { formId: string; page: number; totalPages: number }) {
  const navigate = useNavigate()
  const isLastPageActive = page >= totalPages

  return (
    <div className="flex gap-[8px] justify-center">
      <Button disabled={page <= 1} onClick={prevClick}>
        Prev
      </Button>
      {isLastPageActive ? (
        <Button disabled onClick={nextClick}>
          Submit
        </Button>
      ) : (
        <Button onClick={nextClick}>Next</Button>
      )}
    </div>
  )

  function nextClick() {
    navigate({
      to: '/forms/$id/$mode',
      params: { id: formId, mode: 'preview' },
      search: { page: page + 1 },
    })
  }

  function prevClick() {
    navigate({
      to: '/forms/$id/$mode',
      params: { id: formId, mode: 'preview' },
      search: { page: page - 1 },
    })
  }
}
